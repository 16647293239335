<template>
  <div>
    <loading v-if="!loaded" />
    <div v-else>
      <c-table
        :data="currentQueryData"
        primary-key="autoId"
        :config-string="template"
        default-sort-dir="asc"
        @selectionChanged="onSelectionChanged"
        :teleport="true"
        :overview-id="id"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import LiveGraph from "@/components/LiveGraph.vue";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    let promises = [];
    promises.push(store.dispatch("config/loadOwnOverviews"));
    promises.push(store.dispatch("queries/loadQueryList"));
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "web_overviews" })
    );
    Promise.all(promises).then(() => {
      this.loaded = true;
    });
  },
  computed: {
    ...mapState({
      ownOverviews: (state) => state.config.ownOverviews,
      queryList: (state) => state.queries.list,
      queryData: (state) => state.queries.data,
      processParametersLoaded: (state) =>
        state.livedata.processParametersLoaded,
    }),
    ...mapGetters({
      linkFromProcessParameter: "livedata/linkFromProcessParameter",
      columnConfigFromTemplateName: "config/columnConfigByTemplate",
    }),
    id() {
      return this.$route.params.id;
    },
    template() {
      return "web_own_overview_" + this.id;
    },
    configTemplate() {
      return this.template + "_baseconfig";
    },
    currentOverview() {
      return this.ownOverviews.find((o) => o.record_no == this.id);
    },
    currentQueryId() {
      return this.currentOverview?.sql_query_id;
    },
    currentQuery() {
      return (
        this.queryList.find((q) => q.record_no == this.currentQueryId) || null
      );
    },
    currentQueryData() {
      let i = 0;
      let baseId = this.currentQueryId;
      return (
        this.queryData[this.currentQueryId]?.map((d) => {
          i++;
          return {
            ...d,
            autoId: baseId + "-" + (''+i).padStart(6,'0'),
          };
        }) || []
      );
    },
    baseConfig() {
      return this.columnConfigFromTemplateName(this.configTemplate);
    },
  },
  methods: {
    mergedLink(link) {
      let r = this.linkFromProcessParameter(link.record_no);
      console.warn("merging links, shouldn't happen often.");
      return {
        ...link,
        ...r,
      };
    },
  },
  watch: {
    currentQuery() {
      if (this.currentQuery?.record_no) {
        store.dispatch("queries/loadQueryData", {
          record_no: this.currentQuery.record_no,
          parameters: this.baseConfig?.queryParameters,
        });
      }
    },
  },
  components: {
    LiveGraph,
  },
};
</script>

<style>
</style>